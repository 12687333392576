const state = () => ({
    count: 0
})

const getters = {
    count: (state, getters, rootState) => {
        return state.count
    }
}

const mutations = {
    INCREMENT(state) {
        state.count++
    },
    DECREMENT(state) {
        state.count++
    }
}

const actions = {
    checkout({ commit, state }, products) { }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
