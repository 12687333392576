import Vue from 'vue'
import Vuex from 'vuex'
import SampleModule from './modules/sampleModule'
Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        SampleModule
    },
    strict: false,
    plugins: []
})

export default store
