import Vue from 'vue'

import Store from './store/index'

import './modules/vue-slick-carousel'
import './modules/vue2-smooth-scroll'
import './modules/vue-scrollactive'
import './modules/vue-plyr'

const app = new Vue({
    el: '#app',
    store: Store,
})

window.app = app
